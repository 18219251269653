import React, {useEffect, useContext} from "react"
import {LocaleContext} from "../../locales/locales"
import {config} from "../../config"
import {logEvent} from "../../state/amplitude"
import appleSvg from "../../assets/login/apple.svg"
import googleSvg from "../../assets/login/google.svg"
import {useLoginButtonClickedStore} from "../../loginButtonClickedStore"

export function SignIn() {
  useEffect(() => {
    logEvent("Login")
  }, [])
  const locale = useContext(LocaleContext)
  const setLoginButtonClicked = useLoginButtonClickedStore(
    (state) => state.setLoginButtonClicked
  )
  return (
    <div className="flex flex-col items-center mx-3">
      <h2 className="mt-40 text-center text-3xl leading-9 font-extrabold text-gray-900">
        Postme
      </h2>

      <p className="uppercase mt-3 text-center text-sm leading-5 text-gray-400">
        {locale.signIn.enter}
      </p>

      <button
        className="mt-12 group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none active:bg-gray-400 transition duration-150 ease-in-out"
        onClick={() => {
          setLoginButtonClicked(true)
          window.location.replace(`${config.API_URL}/auth/google`)
        }}
        style={{
          maxWidth: "336px",
          height: "52px",
        }}
        type="submit"
      >
        <img alt="google" className="mr-2" src={googleSvg} />

        {locale.signIn.google}
      </button>

      <button
        className="mt-3 group relative w-full flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-black bg-gray-200 hover:bg-gray-300 focus:outline-none active:bg-gray-400 transition duration-150 ease-in-out"
        onClick={() => {
          setLoginButtonClicked(true)
          window.location.replace(`${config.API_URL}/auth/apple`)
        }}
        style={{
          maxWidth: "336px",
          height: "52px",
        }}
        type="submit"
      >
        <img alt="apple" className="mr-2 -mt-1" src={appleSvg} />

        {locale.signIn.apple}
      </button>

      <p className="max-w-xs mt-11 text-sm text-center text-gray-600">
        <span>{locale.signIn.policy1} </span>

        <span>
          <a
            className="underline hover:text-gray-400"
            href="https://postmeapp.ru/pp-web"
            rel="noopener noreferrer"
            target="_blank"
          >
            {locale.signIn.policy2}
          </a>{" "}
        </span>

        <span>{locale.signIn.policy3} </span>

        <a
          className="underline hover:text-gray-400"
          href="https://postmeapp.ru/tnc-web"
          rel="noopener noreferrer"
          target="_blank"
        >
          {locale.signIn.policy4}
        </a>
      </p>
    </div>
  )
}
