import React from "react"
import {FormattedMessage} from "react-intl"
import {useQuery} from "react-query"
import {useLocaleStore} from "../../locales/locales"
import type {CurrentUser} from "../../shared"

export function Profile() {
  const currentUserQuery = useQuery<CurrentUser>({
    // This query should already be fetched in the parent component responsible for auth. If it's not, add queryFn here.
    queryKey: ["current-user"],
  })
  const currentLocaleName = useLocaleStore((state) => state.localeName)
  const setLocaleName = useLocaleStore((state) => state.setLocaleName)
  return (
    <div className="mx-7 my-5">
      <p className="font-extrabold" style={{fontSize: "26px"}}>
        <FormattedMessage defaultMessage="My Profile" />
      </p>

      <p className="mt-4 text-lg">{currentUserQuery.data?.email}</p>

      <p className="mt-7 font-extrabold" style={{fontSize: "26px"}}>
        <FormattedMessage defaultMessage="Language:" />
      </p>

      <div className="mt-3">
        <input
          checked={currentLocaleName === "en"}
          className="ml-1 transform scale-150"
          id="en"
          name="language"
          onChange={() => {
            setLocaleName("en")
          }}
          type="radio"
          value="en"
        />

        <label className="ml-3 text-lg" htmlFor="en">
          <span aria-label="ru" role="img">
            🇬🇧
          </span>{" "}
          English
        </label>
      </div>

      <div className="mt-1">
        <input
          checked={currentLocaleName === "ru"}
          className="ml-1 transform scale-150"
          id="ru"
          name="language"
          onChange={() => {
            setLocaleName("ru")
          }}
          type="radio"
          value="ru"
        />

        <label className="ml-3 text-lg" htmlFor="ru">
          <span aria-label="ru" role="img">
            🇷🇺
          </span>{" "}
          Русский
        </label>
      </div>
    </div>
  )
}
