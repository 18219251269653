export const ru = {
  documentTitle: "Postme Web - планируйте посты для Инстаграма",
  editor: {
    newPost: "Новый пост",
    close: "Закрыть",
    yourPostsText: "Ваш текст поста",
    save: "Сохранить",
    delete: "Удалить",
    deleteMedia: "Удалить медиа",
  },
  updatePostEditor: {
    instagramPost: "Пост для Instagram",
  },
  uploader: {
    addMedia: "Добавить медиа",
  },
  menu: {
    newPost: "Новый пост",
    posts: "Посты",
    accounts: "Аккаунты",
    profile: "Профиль",
    exit: "Выйти",
  },
  signIn: {
    enter: "Вход",
    apple: "Войти через Apple",
    google: "Войти через Google",
    policy1: "Осуществляя вход, вы соглашаетесь с",
    policy2: "Политикой конфиденциальности",
    policy3: "и",
    policy4: "Лицензионным соглашением",
  },
}
