import React, {useEffect} from "react"
import {FormattedMessage, useIntl} from "react-intl"
import {useMutation, useQuery, useQueryClient} from "react-query"
import plusIcon from "../../assets/posts/plus.svg"
import {Media} from "../../components/Media/Media"
import {Spinner} from "../../components/Spinner/Spinner"
import {config} from "../../config"
import {dayjs} from "../../dayjs"
import {useEditorStore} from "../../editorStore"
import type {Item} from "../../shared"
import {ItemsResult} from "../../shared"
import {amplitudeClient, logEvent} from "../../state/amplitude"
import {trpc} from "../../trpc"
import {readFile} from "../../utils/utils"

export function Post({item}: {item: Item}) {
  const queryClient = useQueryClient()

  const deletePostMutation = useMutation({
    mutationKey: ["delete-post", item.id],
    mutationFn: async (itemId: number) => {
      const response = await fetch(`${config.API_URL}/items/${itemId}`, {
        method: "DELETE",
      })
      const result = await response.json()

      if (result.status === "success") {
        logEvent("List_Delete", {itemId})
      }

      return result
    },
    onSettled: (data, err, vars, ctx) => {
      queryClient.invalidateQueries(["items"])
    },
  })

  const openUpdate = useEditorStore((state) => state.openUpdate)

  const postImagesQuery = useQuery({
    queryKey: ["post-images", item.id],
    queryFn: async ({signal}) => {
      const media = JSON.parse(item.media || "[]") as any[]
      const images = await Promise.all(
        media.map(async (mediaItem) => {
          const response = await fetch(
            `${config.API_URL}/static/${mediaItem.fileName}`,
            {signal}
          )
          const blob = await response.blob()
          const file = new File([blob], mediaItem.fileName.slice(37), {
            type: mediaItem.mimetype,
          })
          return readFile(file)
        })
      )
      return images
    },
    // The query is fetched on demand when the user clicks on the post. We need it here to read the query's status, to open post form once the query is loaded. Therefore it's disabled.
    enabled: false,
  })

  const trpcContext = trpc.useContext()

  const intl = useIntl()

  return (
    <div
      className="bg-gray-50 border rounded-md hover:bg-white p-4 text-sm w-80 h-44 flex flex-col focus:outline-none cursor-pointer"
      onClick={() => {
        trpcContext.prefetchQuery(["connected-ig-accounts"])
        postImagesQuery.refetch().then(({data: images}) => {
          if (!images) {
            return
          }
          logEvent("List_Open", {itemId: item.id})
          openUpdate({
            postId: item.id,
            postText: item.text || "",
            postImages: images,
            publishAt: item.publishAt,
            connectedIgAccountId: item.connectedIgAccountId,
            publishedAt: item.publishedAt,
            publishingErroredAt: item.publishingErroredAt,
          })
        })
      }}
    >
      <div className="overflow-hidden flex flex-grow w-full">
        <div className="flex-grow overflow-hidden">
          {item.publishingErroredAt ? (
            <section className="text-xs font-semibold mb-2">
              <p>
                <FormattedMessage defaultMessage="Not published:" />
              </p>

              <p>{dayjs(item.publishingErroredAt).format("llll")}</p>
            </section>
          ) : item.publishedAt ? (
            <section className="text-xs font-semibold mb-2">
              <p>
                <FormattedMessage defaultMessage="Published:" />
              </p>

              <p>{dayjs(item.publishedAt).format("llll")}</p>
            </section>
          ) : item.publishAt ? (
            <section className="text-xs font-semibold mb-2">
              <p>
                <FormattedMessage defaultMessage="Scheduled:" />
              </p>

              <p>{dayjs(item.publishAt).format("llll")}</p>
            </section>
          ) : null}

          <div className="whitespace-pre-wrap truncate">
            {item.text || (
              <span className="text-gray-400">
                <FormattedMessage defaultMessage="No text" />
              </span>
            )}
          </div>
        </div>

        <div className="pl-2" />

        <Media media={item.media} />
      </div>

      <div className="flex text-gray-400 font-medium mt-1 focus:outline-none">
        {postImagesQuery.isLoading ? (
          <div>
            <Spinner className="text-gray-400" />
          </div>
        ) : null}

        {dayjs(item.updated_at).format("llll")}

        <span className="mx-3">&bull;</span>

        <button
          className="text-xs hover:underline"
          disabled={deletePostMutation.isLoading}
          id={item.id.toString()}
          onClick={(event) => {
            event.stopPropagation()
            if (
              window.confirm(
                intl.formatMessage({
                  defaultMessage: "Are you sure you want to delete this post?",
                })
              )
            ) {
              deletePostMutation.mutate(item.id)
            }
          }}
          type="button"
        >
          <FormattedMessage defaultMessage="Delete" />
        </button>
      </div>
    </div>
  )
}

const byDescCreatedAt = (a: Item, b: Item) => {
  return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
}

export function PostsPure({items}: {items: Item[]}) {
  useEffect(() => {
    logEvent("List")
  }, [])
  const openNew = useEditorStore((state) => state.openNew)
  return (
    <div className="m-3 flex flex-wrap content-start">
      <div className="m-2">
        <button
          className="bg-gray-50 border rounded-md hover:bg-white p-4 text-sm w-80 h-44 flex flex-col focus:outline-none"
          onClick={() => {
            logEvent("List_Add")
            openNew()
          }}
        >
          <div className="flex flex-col items-center justify-center w-full h-full">
            <img alt="plusIcon" src={plusIcon} />

            <p className="mt-2 font-semibold text-gray-500">
              <FormattedMessage defaultMessage="New post" />
            </p>
          </div>
        </button>
      </div>

      {items.sort(byDescCreatedAt).map((item, key) => (
        <div key={key} className="m-2">
          <Post item={item} />
        </div>
      ))}
    </div>
  )
}

export function Posts() {
  const itemsQuery = useQuery({
    queryKey: ["items"],
    queryFn: async ({signal}) => {
      const resp = await fetch(`${config.API_URL}/items`, {signal})
      const rawResult = await resp.json()
      const result = ItemsResult.parse(rawResult)
      const items = result.data

      amplitudeClient.setUserProperties({
        Count_posts: items.length,
      })

      return items
    },
  })

  if (!itemsQuery.isSuccess) {
    return null
  }

  return <PostsPure items={itemsQuery.data} />
}
