export const en = {
  documentTitle: "Postme Web - plan your Instagram posts",
  editor: {
    newPost: "New post",
    close: "Close",
    yourPostsText: "Your post's text",
    save: "Save",
    delete: "Delete",
    deleteMedia: "Delete media",
  },
  updatePostEditor: {
    instagramPost: "Instagram Post",
  },
  uploader: {
    addMedia: "Add media",
  },
  menu: {
    newPost: "New post",
    posts: "Posts",
    accounts: "Acccounts",
    profile: "Profile",
    exit: "Exit",
  },
  signIn: {
    enter: "Login",
    apple: "Continue with Apple",
    google: "Continue with Google",
    policy1: "By logging in, you accept",
    policy2: "Privacy Policy",
    policy3: "and",
    policy4: "Terms and Conditions",
  },
}
