import React from "react"
import {useHistory, useLocation} from "react-router-dom"
import {useLocaleContext} from "../../locales/locales"
import {logEvent} from "../../state/amplitude"
import {config} from "../../config"
import newIcon from "../../assets/menu/new.svg"
import postsIcon from "../../assets/menu/posts.svg"
import profileIcon from "../../assets/menu/profile.svg"
import exitIcon from "../../assets/menu/exit.svg"
import accountsIcon from "../../assets/menu/accounts.svg"
import {useEditorStore} from "../../editorStore"
import {useQueryClient} from "react-query"
import clsx from "clsx"

function MenuItem({
  icon,
  text,
  isMatch,
  handleClick,
}: {
  icon: string
  text: string
  isMatch?: boolean
  handleClick: () => void
}) {
  return (
    <div className={clsx("flex h-12", isMatch && "bg-gray-200")}>
      <button
        className="flex items-center w-full hover:bg-gray-200 focus:outline-none"
        onClick={handleClick}
      >
        <div className={clsx("w-1 h-full", isMatch && "bg-blue-600")} />

        <img alt={`${text}ButtonIcon`} className="w-5 h-auto m-3" src={icon} />

        {text}
      </button>
    </div>
  )
}

export function Menu() {
  const history = useHistory()
  const location = useLocation()
  const locale = useLocaleContext()
  const openNew = useEditorStore((state) => state.openNew)
  const queryClient = useQueryClient()
  return (
    <div
      className="fixed h-full w-60 bg-gray-50"
      style={{
        minWidth: "240px",
      }}
    >
      <div className="flex flex-col h-full">
        <p className="m-5 text-2xl font-extrabold">Postme</p>

        <div>
          <MenuItem
            handleClick={() => {
              openNew()
              logEvent("Add")
            }}
            icon={newIcon}
            text={locale.menu.newPost}
          />

          <MenuItem
            handleClick={() => history.push("/")}
            icon={postsIcon}
            isMatch={location.pathname === "/"}
            text={locale.menu.posts}
          />

          <MenuItem
            handleClick={() => {
              history.push("/accounts")
            }}
            icon={accountsIcon}
            isMatch={location.pathname.startsWith("/accounts")}
            text={locale.menu.accounts}
          />

          <MenuItem
            handleClick={() => {
              history.push("/profile")
              logEvent("Profile")
            }}
            icon={profileIcon}
            isMatch={location.pathname === "/profile"}
            text={locale.menu.profile}
          />
        </div>

        <div className="flex-grow" />

        <MenuItem
          handleClick={() => {
            logEvent("Exit")
            queryClient.clear()
            window.location.replace(`${config.API_URL}/auth/logout`)
          }}
          icon={exitIcon}
          text={locale.menu.exit}
        />
      </div>
    </div>
  )
}
