import create from "zustand"
import {logEvent} from "./state/amplitude"
import type {ReadFile} from "./utils/utils"

type EditorStoreState =
  | {
      state: "closed"
      postId: undefined
      postText: ""
      postImages: never[]
      publishAt: undefined
      connectedIgAccountId: undefined
      publishedAt: undefined
      publishingErroredAt: undefined
    }
  | {
      state: "new"
      postId: undefined
      postText: string
      postImages: ReadFile[]
      publishAt: undefined
      connectedIgAccountId: undefined
      publishedAt: undefined
      publishingErroredAt: undefined
    }
  | {
      state: "update"
      postId: number
      postText: string
      postImages: ReadFile[]
      publishAt: string | undefined | null
      connectedIgAccountId: string | undefined | null
      publishedAt: string | undefined | null
      publishingErroredAt: string | undefined | null
    }

// TODO: Replace this with navigation
export const useEditorStore = create<
  EditorStoreState & {
    close: () => void
    openNew: () => void
    openUpdate: (updatePostVals: {
      postId: number
      postText: string
      postImages: ReadFile[]
      publishAt: string | undefined | null
      connectedIgAccountId: string | undefined | null
      publishedAt: string | undefined | null
      publishingErroredAt: string | undefined | null
    }) => void
  }
>((set) => ({
  state: "closed",
  postId: undefined,
  postText: "",
  postImages: [],
  publishAt: undefined,
  connectedIgAccountId: undefined,
  publishedAt: undefined,
  publishingErroredAt: undefined,
  close: () => {
    set({
      state: "closed",
      postId: undefined,
      postText: "",
      postImages: [],
      publishAt: undefined,
      connectedIgAccountId: undefined,
    } as EditorStoreState)
  },
  openNew: () => {
    set({
      state: "new",
      postId: undefined,
      postText: "",
      postImages: [],
      publishAt: undefined,
      connectedIgAccountId: undefined,
      publishedAt: undefined,
      publishingErroredAt: undefined,
    } as EditorStoreState)
  },
  openUpdate: ({
    postId,
    postText,
    postImages,
    publishAt,
    connectedIgAccountId,
    publishedAt,
    publishingErroredAt,
  }) => {
    set({
      state: "update",
      postId,
      postText,
      postImages,
      publishAt,
      connectedIgAccountId,
      publishedAt,
      publishingErroredAt,
    } as EditorStoreState)
  },
}))

useEditorStore.subscribe((state) => {
  if (state.state === "closed") {
    logEvent("List")
  } else if (state.state === "new") {
    logEvent("Editor_New")
  } else if (state.state === "update") {
    logEvent("Editor")
  }
})
