import type {FC} from "react"
import {useEffect} from "react"
import type {IntlShape} from "react-intl"
import {createIntl, createIntlCache, RawIntlProvider} from "react-intl"
import create from "zustand"
// We don't need to import en locale messages because they're already in the code as defaultMessage`s
import ruMessages from "../locales/compiled/ru.json"
import type {LocaleName} from "../locales/locales"
import {defaultLocaleName, useLocaleStore} from "../locales/locales"

const intlCache = createIntlCache()

const makeIntlForLocale = (localeName: LocaleName) => {
  const intl = createIntl(
    {
      locale: localeName,
      defaultLocale: defaultLocaleName,
      // We don't need enMessages here because they're already in the code as defaultMessage`s
      messages: localeName === "ru" ? ruMessages : undefined,
    },
    intlCache
  )
  return intl
}

export const useIntlStore = create<{
  intl: IntlShape
  setIntl: (intl: IntlShape) => void
}>((set) => {
  const localeName = useLocaleStore.getState().localeName
  return {
    intl: makeIntlForLocale(localeName),
    setIntl: (intl: IntlShape) => set({intl}),
  }
})

export const CustomIntlProvider: FC = ({children}) => {
  const localeName = useLocaleStore((state) => state.localeName)
  const intl = useIntlStore((state) => state.intl)
  const setIntl = useIntlStore((state) => state.setIntl)
  useEffect(() => {
    setIntl(makeIntlForLocale(localeName))
  }, [localeName, setIntl])
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>
}
