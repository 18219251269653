import {z} from "zod"
import bytes from "bytes"
import {useIntlStore} from "../components/CustomIntlProvider"

export const MediaKind = z.enum(["image", "video"])
export type MediaKind = z.infer<typeof MediaKind>

// https://support.buffer.com/hc/en-us/articles/360037965494-Instagram-s-accepted-aspect-ratio-ranges
export const ReadFile = z.union([
  z.object({
    mediaKind: z.literal(MediaKind.Enum.image),
    file: z.instanceof(File),
    dataURL: z.string(),
    type: z.string(),
    width: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Image resolution should be no smaller than 150 by 150 pixels",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .min(150),
    height: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Image resolution should be no smaller than 150 by 150 pixels",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .min(150),
    aspectRatio: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_big) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Image aspect ratio should be no larger than 300:157 (1200:628)",
              }),
            }
          }
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Image aspect ratio should be no smaller than 4:5",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .min(0.8)
      .max(1.91),
    size: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_big) {
            return {
              message: intl.formatMessage({
                defaultMessage: "Image size should be no larger than 8 Mb",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .positive()
      .max(bytes("8Mb")),
  }),

  z.object({
    mediaKind: z.literal(MediaKind.Enum.video),
    file: z.instanceof(File),
    dataURL: z.string(),
    type: z.string(),
    width: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video resolution should be no smaller than 150 by 150 pixels",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .min(150),
    height: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video resolution should be no smaller than 150 by 150 pixels",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .min(150),
    aspectRatio: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_big) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video aspect ratio should be no larger than 16:9",
              }),
            }
          }
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video aspect ratio should be no smaller than 4:5",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .min(0.8)
      .max(1.78),
    size: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_big) {
            return {
              message: intl.formatMessage({
                defaultMessage: "Video size should be no larger than 4 Gb",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .int()
      .positive()
      .max(bytes("4Gb")),
    duration: z
      .number({
        errorMap: (issue, ctx) => {
          const intl = useIntlStore.getState().intl
          if (issue.code === z.ZodIssueCode.too_big) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video duration should be no longer than 60 seconds",
              }),
            }
          }
          if (issue.code === z.ZodIssueCode.too_small) {
            return {
              message: intl.formatMessage({
                defaultMessage:
                  "Video duration should be no shorter than 3 seconds",
              }),
            }
          }
          return {message: ctx.defaultError}
        },
      })
      .min(3)
      .max(60),
  }),
])
export type ReadFile = z.infer<typeof ReadFile>

export function readFile<T extends File>(file: T) {
  const reader = new FileReader()
  return new Promise<ReadFile>((resolve, reject) => {
    reader.addEventListener("load", () => {
      if (file.type.startsWith("image")) {
        const imageEl = document.createElement("img")
        imageEl.addEventListener("load", () => {
          const aspectRatio = imageEl.naturalWidth / imageEl.naturalHeight
          console.log({aspectRatio})
          resolve({
            mediaKind: MediaKind.Enum.image,
            file,
            dataURL: String(
              (reader.result as string).replace(
                ";base64",
                `;name=${encodeURIComponent(file.name)};base64`
              )
            ),
            size: file.size,
            type: file.type,
            width: imageEl.naturalWidth,
            height: imageEl.naturalHeight,
            aspectRatio,
          })
        })
        imageEl.src = reader.result as string
      } else if (file.type.startsWith("video")) {
        const videoEl = document.createElement("video")
        videoEl.addEventListener("loadedmetadata", () => {
          const aspectRatio = videoEl.videoWidth / videoEl.videoHeight
          resolve({
            mediaKind: MediaKind.Enum.video,
            file,
            dataURL: String(
              (reader.result as string).replace(
                ";base64",
                `;name=${encodeURIComponent(file.name)};base64`
              )
            ),
            size: file.size,
            type: file.type,
            width: videoEl.videoWidth,
            height: videoEl.videoHeight,
            aspectRatio,
            duration: videoEl.duration,
          })
        })
        videoEl.src = reader.result as string
      } else {
        reject(new Error("Incorrect file type"))
      }
    })
    reader.readAsDataURL(file)
  })
}

export const settled = async <T extends unknown>(
  promise: Promise<T>
): Promise<PromiseSettledResult<T>> => {
  const settledPromise = (await Promise.allSettled([promise]))[0]
  return settledPromise
}
