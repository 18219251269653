import amplitude from "amplitude-js"
import {v4 as uuidv4} from "uuid"
import {config} from "../config"

const amplitudeClient = amplitude.getInstance()
amplitudeClient.init(config.AMPLITUDE_API_KEY)
amplitudeClient.setUserId(uuidv4())

export const logEvent: typeof amplitudeClient.logEvent = (...args) =>
  amplitudeClient.logEvent(...args)

export {amplitudeClient}
