import {zodResolver} from "@hookform/resolvers/zod"
import {TRPCClientError} from "@trpc/client"
import clsx from "clsx"
import type {FC, ReactNode} from "react"
import {useForm} from "react-hook-form"
import {FormattedMessage, useIntl} from "react-intl"
import {useHistory} from "react-router-dom"
import {Spinner} from "../components/Spinner/Spinner"
import {ConnectInstagramValues, ErrorCode} from "../shared"
import {trpc} from "../trpc"
import {settled} from "../utils/utils"

export const ConnectAccount: FC = () => {
  const form = useForm<ConnectInstagramValues>({
    resolver: zodResolver(ConnectInstagramValues),
  })
  const history = useHistory()
  const trpcContext = trpc.useContext()
  const connectInstagramMutation = trpc.useMutation("connect-ig-account", {
    onSuccess: (data, vars, ctx) => {
      history.replace("/accounts")
    },
    onSettled: (data, err, vars, ctx) => {
      trpcContext.invalidateQueries(["connected-ig-accounts"])
    },
  })
  const intl = useIntl()
  return (
    <div className="mx-7 my-5">
      <p className="font-extrabold" style={{fontSize: "26px"}}>
        <FormattedMessage defaultMessage="Connect Instagram" />
      </p>

      <div className="w-80">
        <p className="mt-4 text-sm text-gray-500 leading-normal">
          <FormattedMessage defaultMessage="Подключите Instagram, чтобы посты публиковались автоматически:" />
        </p>

        <div className="pt-3" />

        <form
          onSubmit={form.handleSubmit((values) =>
            settled(connectInstagramMutation.mutateAsync(values))
          )}
        >
          <input
            autoComplete="username"
            className="block border rounded-lg py-2 px-4 w-full"
            placeholder={intl.formatMessage({defaultMessage: "Username"})}
            type="text"
            {...form.register("login")}
          />

          <div className="pt-4" />

          <input
            autoComplete="current-password"
            className="border block rounded-lg py-2 px-4 w-full"
            placeholder={intl.formatMessage({defaultMessage: "Password"})}
            type="password"
            {...form.register("password")}
          />

          <div className="pt-4" />

          <div className="border bg-gray-50 text-gray-500 rounded-lg py-2 px-4 text-center text-sm">
            <FormattedMessage defaultMessage="We do not store your passwords" />
          </div>

          <div className="pt-4" />

          <div className="border bg-gray-50 text-gray-500 rounded-lg py-3 px-4 text-sm">
            <FormattedMessage
              defaultMessage="<p>Make sure that:</p><ol><li>This account has an active phone number or email connected, and that you have access to them.</li><li>This is not a new Instagram account, it has more than 5 posts, the latest post was published no more than 2 weeks ago.</li></ol>"
              values={{
                p: (chunks: ReactNode) => <p>{chunks}</p>,
                ol: (chunks: ReactNode) => (
                  <ol className="list-decimal list-inside">{chunks}</ol>
                ),
                li: (chunks: ReactNode) => <li className="mt-3">{chunks}</li>,
              }}
            />
          </div>

          <div className="pt-5" />

          <button
            className={clsx(
              "mt-2 py-2 px-18 flex justify-center border border-transparent text-xs leading-5 font-semibold rounded-md text-white bg-blue-600 focus:outline-none active:bg-blue-700 transition duration-150 ease-in-out uppercase tracking-widest w-full",
              connectInstagramMutation.isLoading
                ? "opacity-80"
                : "hover:bg-blue-700"
            )}
            disabled={connectInstagramMutation.isLoading}
            type="submit"
          >
            {connectInstagramMutation.isLoading && (
              <Spinner className="text-white" />
            )}

            {connectInstagramMutation.isLoading ? (
              <FormattedMessage defaultMessage="Connecting..." />
            ) : (
              <FormattedMessage defaultMessage="Connect" />
            )}
          </button>

          <div className="pt-2"></div>

          {connectInstagramMutation.isLoading ? (
            <p className="text-sm text-center text-gray-500">
              <FormattedMessage defaultMessage="Подключение может занять несколько минут" />
            </p>
          ) : connectInstagramMutation.isError ? (
            <p className="text-sm text-center text-red-500">
              {connectInstagramMutation.error instanceof TRPCClientError &&
              connectInstagramMutation.error.message ===
                ErrorCode.enum.CANNOT_CONNECT_ALREADY_CONNECTED_ACCOUNT ? (
                <FormattedMessage defaultMessage="Instagram аккаунт уже подключен к аккаунту на PostMe" />
              ) : (
                <FormattedMessage defaultMessage="Something went wrong, please try again" />
              )}
            </p>
          ) : null}
        </form>
      </div>
    </div>
  )
}
