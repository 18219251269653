import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import dayOfYear from "dayjs/plugin/dayOfYear"
import weekOfYear from "dayjs/plugin/weekOfYear"

dayjs.extend(weekOfYear)

dayjs.extend(dayOfYear)

dayjs.extend(localizedFormat)

dayjs.extend(utc)
dayjs.extend(timezone) // dependent on utc plugin

export {dayjs}
