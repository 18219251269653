import ms from "ms"
import type {FC} from "react"
import {useEffect} from "react"
import React, {StrictMode} from "react"
import ReactDOM from "react-dom"
import {HelmetProvider} from "react-helmet-async"
import "react-image-gallery/styles/css/image-gallery.css"
import {QueryClient, QueryClientProvider} from "react-query"
import {CustomIntlProvider} from "./components/CustomIntlProvider"
import {config} from "./config"
import {LocaleProvider} from "./locales/locales"
import {reportWebVitals} from "./reportWebVitals"
import {Router} from "./router/Router"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import "./styles.css"
import {trpc} from "./trpc"
import type {FallbackProps} from "react-error-boundary"
import {ErrorBoundary} from "react-error-boundary"
import {FormattedMessage} from "react-intl"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Quite high stale time for now to keep it easy on the server for now. We can lower this if we see that server useage is low (e.i. response time not affected).
      staleTime: ms("1m"),
    },
  },
})

const trpcClient = trpc.createClient({
  url: `${config.API_URL}/trpc`,
})

const ErrorFallback: FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  useEffect(() => {
    // TODO: Log error to analytics here
    console.error("Error", error)
  }, [error])
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <p className="mb-4">
        <FormattedMessage defaultMessage="Something went wrong, please try again" />
      </p>

      <button
        className="border uppercase text-xs font-semibold text-black text-opacity-50 py-3 px-6 rounded-lg bg-gray-50 inline-flex items-center tracking-widest"
        onClick={() => {
          window.location.reload()
        }}
        type="button"
      >
        <FormattedMessage defaultMessage="Reload page" />
      </button>
    </div>
  )
}

ReactDOM.render(
  <StrictMode>
    <HelmetProvider>
      <LocaleProvider>
        <CustomIntlProvider>
          {/* Hopefully no errors occur in the components above, because we need i18n to display error fallback :) */}

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <trpc.Provider client={trpcClient} queryClient={queryClient}>
              <QueryClientProvider client={queryClient}>
                <Router />
              </QueryClientProvider>
            </trpc.Provider>
          </ErrorBoundary>
        </CustomIntlProvider>
      </LocaleProvider>
    </HelmetProvider>
  </StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
