import {zodResolver} from "@hookform/resolvers/zod"
import NonNativeFormData from "form-data"
import type {ReactNode} from "react"
import React, {useRef} from "react"
import type {FieldError} from "react-hook-form"
import {Controller, useForm} from "react-hook-form"
import type ReactImageGallery from "react-image-gallery"
import ImageGallery from "react-image-gallery"
import {FormattedMessage, useIntl} from "react-intl"
import {useMutation, useQueryClient} from "react-query"
import {z} from "zod"
import closeIcon from "../../assets/editor/close.svg"
import {Spinner} from "../../components/Spinner/Spinner"
import {config} from "../../config"
import {dayjs} from "../../dayjs"
import {useEditorStore} from "../../editorStore"
import {useLocaleContext} from "../../locales/locales"
import {logEvent} from "../../state/amplitude"
import {trpc} from "../../trpc"
import {ReadFile, readFile} from "../../utils/utils"
import {useIntlStore} from "../CustomIntlProvider"

export function NewPostEditor() {
  const locale = useLocaleContext()

  const close = useEditorStore((state) => state.close)
  const text = useEditorStore((state) => state.postText)
  const images = useEditorStore((state) => state.postImages)

  const queryClient = useQueryClient()

  const newPostMutation = useMutation({
    mutationKey: "new-post",
    mutationFn: async (variables: PostVals) => {
      const formData = new NonNativeFormData()
      variables.images.forEach((item) => formData.append("files", item.file))
      formData.append("text", variables.text)
      if (variables.publishAt && variables.connectedIgAccountId) {
        formData.append("publishAt", variables.publishAt)
        formData.append("connectedIgAccountId", variables.connectedIgAccountId)
      }
      const response = await fetch(`${config.API_URL}/items`, {
        method: "POST",
        body: formData as any,
      })
      const result = await response.json()

      if (result && result.status === "success") {
        logEvent("Editor_Saved", {itemId: result.data.id})
        queryClient.invalidateQueries(["items"])
        close()
      }
    },
  })

  return (
    <Editor
      defaultValues={{text, images}}
      handleClose={() => {
        close()
      }}
      handleSubmit={(values) => {
        newPostMutation.mutate(values)
      }}
      mutationIsLoading={newPostMutation.isLoading}
      publishedAt={undefined}
      publishingErroredAt={undefined}
      title={locale.editor.newPost}
    />
  )
}

export function UpdatePostEditor() {
  const locale = useLocaleContext()

  const close = useEditorStore((state) => state.close)
  const id = useEditorStore((state) => state.postId)
  const text = useEditorStore((state) => state.postText)
  const images = useEditorStore((state) => state.postImages)
  const connectedIgAccountId = useEditorStore(
    (state) => state.connectedIgAccountId
  )
  const publishAt = useEditorStore((state) => state.publishAt)
  const publishedAt = useEditorStore((state) => state.publishedAt)
  const publishingErroredAt = useEditorStore(
    (state) => state.publishingErroredAt
  )

  const queryClient = useQueryClient()

  const updatePostMutation = useMutation({
    mutationKey: ["update-post", id],
    mutationFn: async (variables: PostVals & {id: number}) => {
      const formData = new NonNativeFormData()
      variables.images.forEach((item) => formData.append("files", item.file))
      formData.append("text", variables.text)
      if (variables.publishAt && variables.connectedIgAccountId) {
        formData.append("publishAt", variables.publishAt)
        formData.append("connectedIgAccountId", variables.connectedIgAccountId)
      }
      const response = await fetch(`${config.API_URL}/items/${variables.id}`, {
        method: "PATCH",
        body: formData as any,
      })
      const result = await response.json()
      if (result && result.status === "success") {
        logEvent("Editor_Saved", {itemId: variables.id})
        queryClient.invalidateQueries(["items"])
        close()
      }
    },
    onSettled: (data, err, vars, ctx) => {
      queryClient.invalidateQueries(["post-images", vars.id])
    },
  })

  return (
    <Editor
      defaultValues={{text, images, connectedIgAccountId, publishAt}}
      handleClose={() => {
        close()
      }}
      handleSubmit={(values) => {
        updatePostMutation.mutate({...values, id: id!})
      }}
      mutationIsLoading={updatePostMutation.isLoading}
      publishedAt={publishedAt}
      publishingErroredAt={publishingErroredAt}
      title={locale.updatePostEditor.instagramPost}
    />
  )
}

const zodIssueCodes = Object.values(z.ZodIssueCode)

const flattentZodFieldErrors = (value: any): FieldError | undefined => {
  if (!value) {
    return undefined
  }
  if (zodIssueCodes.includes(value.type)) {
    return value as FieldError
  }
  return Object.values(value).map(flattentZodFieldErrors)[0]
}

const PostVals = z
  .object({
    text: z.string(),
    images: z.array(ReadFile).max(10),
    connectedIgAccountId: z.string().nullish(),
    publishAt: z
      .string()
      .refine(
        (publishAt) => dayjs(publishAt).isAfter(dayjs().add(1, "min")),
        () => {
          const intl = useIntlStore.getState().intl
          return {
            message: intl.formatMessage({
              defaultMessage:
                "Publishing date and time should be at least 1 min in to the future",
            }),
          }
        }
      )
      .refine(
        (publishAt) => dayjs(publishAt).isBefore(dayjs().add(1, "year")),
        () => {
          const intl = useIntlStore.getState().intl
          return {
            message: intl.formatMessage({
              defaultMessage:
                "Publishing date and time should be no more than 1 year in to the future",
            }),
          }
        }
      )
      .nullish(),
  })
  .refine(
    (postVals) => {
      if (postVals.publishAt && !postVals.connectedIgAccountId) {
        return false
      }
      return true
    },
    () => {
      const intl = useIntlStore.getState().intl
      return {
        path: ["connectedIgAccountId"],
        message: intl.formatMessage({
          defaultMessage:
            "Instagram account is required to publish a post to Instagram",
        }),
      }
    }
  )
  .refine(
    (postVals) => {
      if (postVals.publishAt && !postVals.images.length) {
        return false
      }
      return true
    },
    () => {
      const intl = useIntlStore.getState().intl
      return {
        path: ["images"],
        message: intl.formatMessage({
          defaultMessage:
            "At least 1 media item is required to publish a post to Instagram",
        }),
      }
    }
  )
  .refine(
    (postVals) => {
      if (!postVals.text && !postVals.images.length) {
        return false
      }
      return true
    },
    () => ({
      path: ["text"],
      // We don't need intl here because this error message is currently not shown and the error is simply used to focus the textarea
      message: "Required",
    })
  )
type PostVals = z.infer<typeof PostVals>

const datetimeLocalToIso = (datetimeLocal: string) =>
  dayjs(datetimeLocal, "YYYY-MM-DDTHH:mm").toISOString()

const isoToDatetimeLocal = (iso: string) =>
  dayjs(iso).format("YYYY-MM-DDTHH:mm")

export function Editor(props: {
  title?: ReactNode
  mutationIsLoading: boolean
  handleClose: () => void
  defaultValues: PostVals
  handleSubmit: (values: PostVals) => void
  publishedAt: string | undefined | null
  publishingErroredAt: string | undefined | null
}) {
  const locale = useLocaleContext()
  const reactImageGalleryRef = useRef<ReactImageGallery>(null)
  const form = useForm<PostVals>({
    defaultValues: props.defaultValues,
    resolver: zodResolver(PostVals),
  })

  const connectedIgAccountsQuery = trpc.useQuery(["connected-ig-accounts"])

  const fileInputRef = useRef<HTMLInputElement>(null)

  const intl = useIntl()

  return (
    <div className="fixed w-full h-full bg-black bg-opacity-25 z-30">
      <div
        className="absolute left-0 w-3/12 h-full"
        onClick={props.handleClose}
      ></div>

      <div className="absolute right-0 w-9/12 h-full bg-white flex flex-col">
        <div className="m-3 flex-1 flex flex-col">
          <div className="flex border-b items-center">
            <p className="flex-grow text-2xl font-extrabold ml-2 pb-2">
              {props.title}
            </p>

            <div>
              <button
                className="flex text-gray-500 text-sm font-semibold items-center focus:outline-none"
                onClick={props.handleClose}
              >
                {locale.editor.close}

                <img alt="closeIcon" className="ml-2 w-3" src={closeIcon} />
              </button>
            </div>
          </div>

          <form
            className="flex flex-1 w-full mt-3"
            onSubmit={form.handleSubmit(props.handleSubmit)}
          >
            <div className="flex-grow mr-3">
              <textarea
                className="block whitespace-pre-wrap w-full break-all p-2 text-sm h-full"
                placeholder={locale.editor.yourPostsText}
                readOnly={!!props.publishedAt}
                {...form.register("text")}
              />
            </div>

            <div className="flex flex-col w-60">
              <Controller
                control={form.control}
                name="images"
                render={({field, fieldState}) => {
                  const fieldStateError = flattentZodFieldErrors(
                    fieldState.error
                  )
                  return (
                    <div>
                      <div>
                        <ImageGallery
                          ref={reactImageGalleryRef}
                          items={field.value.map((image, index) => ({
                            original: image.dataURL,
                            index,
                          }))}
                          renderItem={(item) => {
                            return item.original.includes("video") ? (
                              <video
                                autoPlay
                                muted
                                style={{
                                  borderRadius: "3px",
                                  width: "9rem",
                                  height: "9rem",
                                  minWidth: "240px",
                                  objectFit: "cover",
                                }}
                              >
                                <source src={item.original} />
                              </video>
                            ) : (
                              <div
                                className="mb-2 w-36 h-36 rounded"
                                style={{
                                  minWidth: "240px",
                                  background: `url(${item.original}) no-repeat center`,
                                  backgroundSize: "cover",
                                }}
                              />
                            )
                          }}
                          showBullets={true}
                          showFullscreenButton={false}
                          showNav={false}
                          showPlayButton={false}
                          showThumbnails={false}
                        />

                        {field.value && !!field.value.length && (
                          <button
                            className="my-2 py-2 px-4 border hover:bg-white text-sm font-medium rounded-md bg-gray-50 focus:outline-none"
                            disabled={!!props.publishedAt}
                            onClick={() => {
                              if (reactImageGalleryRef.current) {
                                const nextImages = field.value.slice()
                                nextImages.splice(
                                  reactImageGalleryRef.current.getCurrentIndex(),
                                  1
                                )
                                field.onChange(nextImages)
                                logEvent("Editor_Media_deleted")
                              }
                            }}
                            style={{
                              minWidth: "240px",
                            }}
                          >
                            {locale.editor.deleteMedia}
                          </button>
                        )}
                      </div>

                      <button
                        className="py-2 px-4 border hover:bg-white text-sm font-medium rounded-md bg-gray-50 focus:outline-none"
                        disabled={!!props.publishedAt}
                        onClick={() => fileInputRef.current?.click()}
                        style={{
                          minWidth: "240px",
                        }}
                        type="button"
                      >
                        {locale.uploader.addMedia}
                      </button>

                      {fieldStateError?.message && (
                        <p className="text-center text-xs text-red-500 my-1">
                          {fieldStateError.message}
                        </p>
                      )}

                      <input
                        ref={fileInputRef}
                        accept="image/*, video/*"
                        multiple={true}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={async (e) => {
                          const nextFiles = await Promise.all(
                            Object.values(e.target.files || {}).map(readFile)
                          )
                          const files = [...field.value, ...nextFiles]
                          field.onChange(files)
                          logEvent("Editor_Media_added", {
                            mediaCount: files.length,
                          })
                        }}
                        style={{
                          opacity: "0",
                          position: "absolute",
                          zIndex: -1,
                        }}
                        type="file"
                      />
                    </div>
                  )
                }}
              />

              <div className="pt-3" />

              <label
                className="text-xs text-gray-500"
                htmlFor="connectedIgAccountId"
              >
                <FormattedMessage defaultMessage="Account:" />
              </label>

              <div className="pt-1" />

              {connectedIgAccountsQuery.isSuccess ? (
                <>
                  <select
                    className="p-2 border rounded-md text-sm bg-white"
                    id="connectedIgAccountId"
                    {...form.register("connectedIgAccountId")}
                    disabled={!!props.publishedAt}
                  >
                    <option value="">
                      {intl.formatMessage({
                        defaultMessage: "--Account not selected--",
                      })}
                    </option>

                    {connectedIgAccountsQuery.data.map((connectedIgAccount) => (
                      <option
                        key={connectedIgAccount.id}
                        value={connectedIgAccount.id}
                      >
                        {connectedIgAccount.username}
                      </option>
                    ))}
                  </select>

                  {form.formState.errors.connectedIgAccountId?.message && (
                    <p className="text-xs my-1 text-red-500">
                      {form.formState.errors.connectedIgAccountId?.message}
                    </p>
                  )}
                </>
              ) : (
                <select
                  className="p-2 border rounded-md text-sm bg-white"
                  disabled
                  id="connectedIgAccountId"
                >
                  <option value="">
                    {intl.formatMessage({
                      defaultMessage: "--Account not selected--",
                    })}
                  </option>
                </select>
              )}

              <div className="pt-3" />

              <label className="text-xs text-gray-500" htmlFor="publishAt">
                <FormattedMessage
                  defaultMessage="Дата и время публикации ({offsetName}):"
                  values={{offsetName: dayjs().offsetName()}}
                />
              </label>

              <div className="pt-1" />

              <Controller
                control={form.control}
                defaultValue={
                  props.defaultValues.publishAt &&
                  isoToDatetimeLocal(props.defaultValues.publishAt)
                }
                name="publishAt"
                render={({field}) => (
                  <input
                    ref={field.ref}
                    className="border p-2 rounded-md text-sm"
                    id="publishAt"
                    max={isoToDatetimeLocal(
                      dayjs().add(1, "year").toISOString()
                    )}
                    min={isoToDatetimeLocal(
                      dayjs().add(1, "min").toISOString()
                    )}
                    name={field.name}
                    onBlur={field.onBlur}
                    onChange={(e) => {
                      const value = e.target.value
                      field.onChange(
                        value ? datetimeLocalToIso(value) : undefined
                      )
                    }}
                    readOnly={!!props.publishedAt}
                    type="datetime-local"
                    value={field.value ? isoToDatetimeLocal(field.value) : ""}
                  />
                )}
              />

              {form.formState.errors.publishAt?.message && (
                <p className="text-xs my-1 text-red-500">
                  {form.formState.errors.publishAt?.message}
                </p>
              )}

              <div className="pt-5" />

              {props.publishingErroredAt && (
                <section className="text-sm font-semibold">
                  <p>
                    <FormattedMessage defaultMessage="Not published:" />
                  </p>

                  <p>{dayjs(props.publishingErroredAt).format("llll")}</p>

                  <div className="pt-5" />
                </section>
              )}

              {props.publishedAt ? (
                <section className="text-sm font-semibold">
                  <p>
                    <FormattedMessage defaultMessage="Published:" />
                  </p>

                  <p>{dayjs(props.publishedAt).format("llll")}</p>
                </section>
              ) : (
                <button
                  className="py-2 px-4 flex justify-center border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none active:bg-blue-700 transition duration-150 ease-in-out resize-none"
                  disabled={props.mutationIsLoading}
                  type="submit"
                >
                  {props.mutationIsLoading ? (
                    <Spinner className="text-white" />
                  ) : null}

                  {locale.editor.save}
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
