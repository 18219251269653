import type {FC} from "react"
import {FormattedMessage} from "react-intl"
import {Link} from "react-router-dom"
import plusIcon from "../assets/posts/plus.svg"
import removeIcon from "../assets/remove.svg"
import {trpc} from "../trpc"
import {settled} from "../utils/utils"

export const Accounts: FC = () => {
  const connectedIgAccountsQuery = trpc.useQuery(["connected-ig-accounts"])

  const trpcContext = trpc.useContext()
  const removeConnectedIgAccountMutation = trpc.useMutation(
    "remove-connected-ig-account",
    {
      onSettled: (data, err, vars, ctx) => {
        trpcContext.invalidateQueries(["connected-ig-accounts"])
      },
    }
  )

  return (
    <div className="mx-7 my-5">
      <h1 className="font-extrabold" style={{fontSize: "26px"}}>
        <FormattedMessage defaultMessage="Instagram Accounts" />
      </h1>

      <div className="pt-6" />

      <div className="w-80">
        <Link
          className="border uppercase text-xs font-semibold text-black text-opacity-50 py-3 px-6 rounded-lg bg-gray-50 inline-flex items-center tracking-widest"
          to="/accounts/connect"
        >
          <img alt="plusIcon" className="w-3 h-3 -ml-1 mr-3" src={plusIcon} />

          <FormattedMessage defaultMessage="Connect account" />
        </Link>

        <div className="pt-6" />

        {!!connectedIgAccountsQuery.data?.length && (
          <ul>
            {connectedIgAccountsQuery.data.map((connectedIgAccount) => (
              <li
                key={connectedIgAccount.id}
                className="flex items-center border-t border-b py-3 pr-1 -mt-px"
              >
                <div>{connectedIgAccount.username}</div>

                <div className="pl-2" />

                <div className="flex-1" />

                <button
                  className="p-1"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to disconnect account ${connectedIgAccount.username}?`
                      )
                    ) {
                      removeConnectedIgAccountMutation.mutate({
                        id: connectedIgAccount.id,
                      })
                    }
                  }}
                >
                  <img alt="Remove" src={removeIcon} />
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
