import type {ReactNode} from "react"
import React from "react"
import {NewPostEditor, UpdatePostEditor} from "../../components/Editor/Editor"
import {Menu} from "../../components/Menu/Menu"
import {useEditorStore} from "../../editorStore"

export function Layout({children}: {children?: ReactNode}) {
  const editorState = useEditorStore((state) => state.state)
  return (
    <div className="flex">
      {editorState === "new" && <NewPostEditor />}

      {editorState === "update" && <UpdatePostEditor />}

      <Menu />

      <div className="min-h-screen ml-60">{children}</div>
    </div>
  )
}
