import getUserLocale from "get-user-locale"
import type {FC} from "react"
import React, {createContext, useContext} from "react"
import {Helmet} from "react-helmet-async"
import {z} from "zod"
import create from "zustand"
import {persist} from "zustand/middleware"
import {en} from "./en"
import {ru} from "./ru"
import "dayjs/locale/en"
import "dayjs/locale/ru"
import {dayjs} from "../dayjs"

export const LocaleName = z.enum(["en", "ru"])
export type LocaleName = z.infer<typeof LocaleName>

export const defaultLocaleName = LocaleName.enum.en

const getLocaleName = (): LocaleName => {
  const localStorageLocale = localStorage.getItem("userLocale")
  const localStorageLocaleParsed = LocaleName.safeParse(localStorageLocale)
  if (localStorageLocaleParsed.success) {
    return localStorageLocaleParsed.data
  }
  const userLocale = getUserLocale().slice(0, 2)
  const userLocaleParsed = LocaleName.safeParse(userLocale)
  if (userLocaleParsed.success) {
    return userLocaleParsed.data
  }
  return defaultLocaleName
}

export const useLocaleStore = create(
  persist(
    (set) => ({
      localeName: getLocaleName() as LocaleName,
      setLocaleName: (localeName: LocaleName) => {
        set({localeName})
      },
    }),
    {
      name: "locale-store",
      // localStorage is used by default
    }
  )
)

dayjs.locale(useLocaleStore.getState().localeName)
useLocaleStore.subscribe(({localeName}) => {
  dayjs.locale(localeName)
})

const locales = {en, ru}

export const LocaleContext = createContext(locales[defaultLocaleName])

export const LocaleProvider: FC = ({children}) => {
  const currentLocaleName = useLocaleStore((state) => state.localeName)
  return (
    <LocaleContext.Provider value={locales[currentLocaleName]}>
      <Helmet>
        <title>{locales[currentLocaleName].documentTitle}</title>
      </Helmet>

      {children}
    </LocaleContext.Provider>
  )
}

export function useLocaleContext() {
  return useContext(LocaleContext)
}
