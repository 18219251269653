import React from "react"

export function Media({
  media,
  index = 0,
}: {
  media?: string | null
  index?: number
}) {
  if (!media) {
    return null
  }
  const nextMedia = JSON.parse(media)
  if (
    nextMedia[index].mimetype &&
    nextMedia[index].mimetype.includes("video/")
  ) {
    return (
      <div
        className="w-12 h-12 rounded"
        style={{
          minWidth: "3rem",
        }}
      >
        <video
          autoPlay
          muted
          style={{
            height: "3rem",
            width: "3rem",
            borderRadius: "3px",
            objectFit: "cover",
          }}
        >
          <source
            src={`/services/static/${encodeURIComponent(
              nextMedia[0].fileName
            )}`}
            type={nextMedia[index].mimetype}
          />
        </video>
      </div>
    )
  }
  return (
    <div
      className="w-12 h-12 rounded"
      style={{
        minWidth: "3rem",
        background: `url(/services/static/${encodeURIComponent(
          nextMedia[index].fileName
        )}) center / cover no-repeat`,
      }}
    />
  )
}
