import {z} from "zod"

// TODO: Refine these
const Login = z.string().min(1).max(255)
const Password = z.string().min(1).max(255)

export const ConnectInstagramValues = z.object({
  login: Login,
  password: Password,
})
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ConnectInstagramValues = z.infer<typeof ConnectInstagramValues>

export const ErrorCode = z.enum(["CANNOT_CONNECT_ALREADY_CONNECTED_ACCOUNT"])

export const Item = z.object({
  id: z.number().int().positive(),
  updated_at: z.string(),
  created_at: z.string(),

  user_id: z.number().int().positive(),
  text: z.string().nullable(),
  media: z.string().nullable(),

  publishAt: z.string().nullish(),
  connectedIgAccountId: z.string().nullish(),
  publishedAt: z.string().nullish(),
  publishingErroredAt: z.string().nullish(),
})
export type Item = z.infer<typeof Item>

export const ItemsResult = z.object({
  status: z.literal("success"),
  data: z.array(Item),
})
export type ItemsResult = z.infer<typeof ItemsResult>

export const CurrentUser = z.object({
  id: z.number().int().positive(),
  created_at: z.string(),
  updated_at: z.string(),

  profile_id: z.string().nullable(),
  provider: z.string().nullable(),
  email: z.string().nullable(),
  client_user_id: z.string().nullable(),
  amplitude_id: z.string().nullable(),
  os: z.string().nullable(),
})
export type CurrentUser = z.infer<typeof CurrentUser>

export const CurrentUserResult = z.object({
  status: z.literal("success"),
  data: CurrentUser,
  hasLoggedIn: z.literal("true").nullable(),
})
export type CurrentUserResult = z.infer<typeof CurrentUserResult>

export const Subscriptions = z.object({
  receipts: z.array(
    z.object({
      expires_date_ms: z.union([z.string(), z.number()]),
      product_id: z.string(),
    })
  ),
  purchase_tokens: z.array(
    z.object({
      expiry_time_millis: z.union([z.string(), z.number()]),
      product_id: z.string(),
    })
  ),
})
export type Subscriptions = z.infer<typeof Subscriptions>

export const SubscriptionsResult = z.object({
  status: z.literal("success"),
  data: Subscriptions,
})
export type SubscriptionsResult = z.infer<typeof SubscriptionsResult>
