import React from "react"
import {BrowserRouter, Switch, Route} from "react-router-dom"
import {SignIn} from "../pages/SignIn/SignIn"
import {Profile} from "../pages/Profile/Profile"
import {Posts} from "../pages/Posts/Posts"
import {Layout} from "../components/Layout/Layout"
import {UserAuthenticated} from "../components/UserAuthenticated/UserAuthenticated"
import {Accounts} from "../pages/Accounts"
import {ConnectAccount} from "../pages/ConnectAccount"

export function Router() {
  return (
    <BrowserRouter>
      <Switch>
        <Route component={SignIn} path="/signIn" />

        <Route path="*">
          <UserAuthenticated>
            <Layout>
              <Switch>
                <Route component={Profile} path="/profile" />

                <Route component={ConnectAccount} path="/accounts/connect" />

                <Route component={Accounts} path="/accounts" />

                <Route component={Posts} path="/" />
              </Switch>
            </Layout>
          </UserAuthenticated>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
