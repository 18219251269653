import React, {useEffect} from "react"
import {useQuery} from "react-query"
import {config} from "../../config"
import {dayjs} from "../../dayjs"
import {useLoginButtonClickedStore} from "../../loginButtonClickedStore"
import {SignIn} from "../../pages/SignIn/SignIn"
import {CurrentUserResult, SubscriptionsResult} from "../../shared"
import {amplitudeClient, logEvent} from "../../state/amplitude"

export function UserAuthenticated({children}: {children: JSX.Element | null}) {
  const currentUserQuery = useQuery({
    queryKey: ["current-user"],
    queryFn: async ({signal}) => {
      const resp = await fetch(`${config.API_URL}/user`, {signal})
      const rawResult = await resp.json()
      const result = CurrentUserResult.parse(rawResult)

      if (!result.hasLoggedIn) {
        logEvent("Login_first_time")
      }

      const user = result.data
      return user
    },
    // TODO: Make this more granular and don't retry only on 401 or 403 errors
    // For now we don't retry this at all because we want to show the log in screen as soon as possible without waiting for retry requests
    retry: false,
  })

  const loginButtonClicked = useLoginButtonClickedStore(
    (state) => state.loginButtonClicked
  )
  const setLoginButtonClicked = useLoginButtonClickedStore(
    (state) => state.setLoginButtonClicked
  )

  useEffect(() => {
    if (!currentUserQuery.isSuccess) {
      return
    }
    amplitudeClient.setUserId(currentUserQuery.data.id.toString())
    amplitudeClient.setUserProperties({
      Email: currentUserQuery.data.email,
      Сohort_day: dayjs(currentUserQuery.data.created_at).dayOfYear(),
      Сohort_week: dayjs(currentUserQuery.data.created_at).week(),
      Сohort_month: dayjs(currentUserQuery.data.created_at).month() + 1,
      Сohort_yeah: dayjs(currentUserQuery.data.created_at).year(),
      Cohort_date: dayjs(currentUserQuery.data.created_at).format("DD.MM.YYYY"),
    })
    logEvent("Session_start")
    if (loginButtonClicked) {
      setLoginButtonClicked(false)
      if (currentUserQuery.data.provider === "google") {
        logEvent("Login_Google_success")
      }
      if (currentUserQuery.data.provider === "apple") {
        logEvent("Login_Apple_success")
      }
    }
  }, [
    // react-query uses structural changes, so we it's ok to pass objects as useEffect dependencies
    currentUserQuery.data,
    currentUserQuery.isSuccess,
    loginButtonClicked,
    setLoginButtonClicked,
  ])

  const subscriptionsQuery = useQuery({
    queryKey: ["subscriptions"],
    queryFn: async ({signal}) => {
      const resp = await fetch(`${config.API_URL}/subscriptions`, {signal})
      const rawResult = await resp.json()
      const result = SubscriptionsResult.parse(rawResult)
      const subscriptions = result.data
      return subscriptions
    },
    enabled: currentUserQuery.isSuccess,
  })

  useEffect(() => {
    if (!subscriptionsQuery.isSuccess) {
      return
    }
    amplitudeClient.setUserProperties({
      Subscription_Apple:
        subscriptionsQuery.data.receipts.length &&
        new Date(subscriptionsQuery.data.receipts[0].expires_date_ms) >
          new Date()
          ? "yes"
          : "no",
      Apple_Exp_date: subscriptionsQuery.data.receipts.length
        ? subscriptionsQuery.data.receipts[0].expires_date_ms
        : "no",
      Apple_Period: subscriptionsQuery.data.receipts.length
        ? subscriptionsQuery.data.receipts[0].product_id
        : "no",

      Subscription_Google:
        subscriptionsQuery.data.purchase_tokens.length &&
        new Date(
          subscriptionsQuery.data.purchase_tokens[0].expiry_time_millis
        ) > new Date()
          ? "yes"
          : "no",
      Google_Exp_date: subscriptionsQuery.data.purchase_tokens.length
        ? subscriptionsQuery.data.purchase_tokens[0].expiry_time_millis
        : "no",
      Google_Period: subscriptionsQuery.data.purchase_tokens.length
        ? subscriptionsQuery.data.purchase_tokens[0].product_id
        : "no",
    })
  }, [
    // react-query uses structural changes, so we it's ok to pass objects as useEffect dependencies
    subscriptionsQuery.data,
    subscriptionsQuery.isSuccess,
  ])

  if (currentUserQuery.isLoading) {
    return null
  }

  return currentUserQuery.isSuccess ? children : <SignIn />
}
