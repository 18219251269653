import create from "zustand"
import {persist} from "zustand/middleware"

// We need to persist this because we redirect on for 3-rd party auth. We lose the state otherwise.
export const useLoginButtonClickedStore = create<{
  loginButtonClicked: boolean
  setLoginButtonClicked: (loginButtonClicked: boolean) => void
}>(
  persist(
    (set) => ({
      loginButtonClicked: false as boolean,
      setLoginButtonClicked: (loginButtonClicked: boolean) =>
        set({loginButtonClicked}),
    }),
    {
      name: "login-button-clicked-store",
      // localStorage is used by default
    }
  )
)
